import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FormAlert from "../components/Forms/Indicators/form-alert"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { useState } from "react"
import FormLoadingIndicator from "../components/Forms/Indicators/form-loading-indicator"
import { VerifyEmail } from "../utll/firebase"
import { Breadcrumb } from "../components/Breadcrumb/Breadcrumb"

const links = [
  { displayName: "Home", link: "/" },
  { displayName: "About", link: "/#about" },
  { displayName: "Contact", link: "/#contact" },
  { displayName: "Press", link: "/press" },
]

type VerificationStatus = {
  sent: boolean
  message: string
  navigationUrl?: string
}
const VerifyPage = () => {
  const location = useLocation()
  const jwt = location.search.split("=")[1]
  const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState<VerificationStatus>({
    sent: true,
    message: "Your email has been verified!",
    navigationUrl: "/",
  })

  useEffect(() => {
    if (jwt) {
      VerifyEmail({
        token: jwt,
      })
        .then(response => {
          const result: any = response.data
          if (!result.error && result.status == 200) {
            setLoading(false)
          } else {
            if (result.error) {
              setStatus({
                sent: false,
                message: result.error.message,
                navigationUrl: result.expired ? "/membership" : "/",
              })
            } else {
              setStatus({
                sent: false,
                message: result.message,
                navigationUrl: "/membership",
              })
            }
            setLoading(false)
          }
        })
        .catch(error => {
          console.log("error", error)
          setStatus({
            sent: false,
            message:
              "An Error occured whilst verifying your email, please try again later.",
            navigationUrl: "/membership",
          })
          setLoading(false)
        })
    } else {
      setLoading(false)
      navigate("/")
    }
  }, [])

  return (
    <Layout navigationLinks={links}>
      <Seo title="Verify Email" />
      <main
        className={"mt-5"}
        id={"main"}
        style={{
          background: "url('../images/hero-bg.jpg') top center",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <Breadcrumb pageNames={[{ name: "Verify" }]} title={"Verify"} />
        <section id="verfiy" className="verify">
          <div
            className="container"
            data-aos="fade-up"
            style={{ height: "40vh" }}
          >
            <div className="section-title">
              <h2>Verify Membership</h2>
            </div>
            <div className="row mt-5 align-items-center">
              <div className="col-lg-8 offset-lg-2 mt-5 mt-lg-0">
                {loading ? (
                  <FormLoadingIndicator
                    message={"verifying your email address"}
                  />
                ) : (
                  <FormAlert
                    status={{ sent: status.sent, message: status.message }}
                    onClick={() => {
                      navigate(status.navigationUrl!)
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default VerifyPage
